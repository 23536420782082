import { createStore } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';

// Use as sesssion storage so that the value is cleared when the tab is closed
// Use as session storage so that the value is cleared when the tab is closed
const storage = createJSONStorage<string | null>(() => sessionStorage);

export const confirmationRedirectURLAtom = atomWithStorage<string | null>(
  'confirmationRedirectURLSession',
  null,
  storage
);
export const pageTitleAtom = atomWithStorage<string | null>(
  'pageTitle',
  null,
  storage
);

export const formStore = createStore();
