'use client';
import { FieldHookConfig, useField } from 'formik';
import { Textarea } from '@/components/shared/material';
import { TextareaProps } from '@material-tailwind/react';

export const TextareaField = ({
  ...props
}: FieldHookConfig<any> & TextareaProps) => {
  const [field, meta] = useField(props);
  return (
    <>
      <Textarea
        {...field}
        variant='standard'
        disabled={props.disabled}
        label={props.label}
        name={props.name}
        error={meta.touched && !!meta.error}
        required={props.required}
        containerProps={props.containerProps}
        placeholder={props.placeholder}
        labelProps={props.labelProps}
        maxLength={props.maxLength}
        className={`peer placeholder:opacity-0 focus:placeholder:opacity-100 ${props.className}`}
      />
      {meta.touched && meta.error ? (
        <div className='text-xs text-red-500'>{meta.error}</div>
      ) : null}
    </>
  );
};

export default TextareaField;
