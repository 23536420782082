export const testIds = {
  purchaseJourneyConfirmPurchase: 'pj-confirm-purchase',
  purchaseJourneyCheckout: 'pj-to-checkout',
  purchaseJourneyCartSlideOutButton: 'pj-cart-slide-out-button',
  purchaseJourneyTotal: 'pj-total',
  purchaseJourneyBuy: 'pj-buy',
  purchaseJourneyCard: 'pj-card',
  cartCloseSlideOut: 'cart-close-slide-out',
  cartQuantityAndPrice: 'cart-quantity-and-price',
  cartDiscountAndTotal: 'cart-discount-and-total',
  purchaseJourneySolPropQuestion: 'pj-sol-prop-question',
  purchaseJourneyDeliveryAddress: 'pj-delivery-address',
  siteHeader: 'site-header',
  siteSignupAndLogin: 'site-signup-and-login',
  homePageBanner: 'home-page-banner',
  funnelPageBanner: 'funnel-page-banner',
  funnelPageContent: 'funnel-page-content',
  funnelNext: 'funnel-next',
  funnelSubmit: 'funnel-submit',
  funnelSkip: 'funnel-skip',
  funnelGetStarted: 'funnel-get-started',
  funnelTools: 'funnel-tools',
  funnelProductResults: 'funnel-product-results',
  funnelCreateProfile: 'funnel-create-profile',
};
