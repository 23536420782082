'use client';
import { Tooltip } from '@material-tailwind/react';
import { Info } from '@phosphor-icons/react';

export default function InputToolTip({ message }: { message: string }) {
  return (
    <Tooltip
      className='relative mr-1 max-w-[240px] bg-charcoal-600 text-xs !opacity-90 after:absolute after:-bottom-1 after:left-0 after:right-0 after:mx-auto after:h-2 after:w-2 after:rotate-45 after:transform after:bg-charcoal-600'
      content={message}
      offset={10}
      animate={{
        mount: { scale: 1, y: 0 },
        unmount: { scale: 0, y: 25 },
      }}
    >
      <span>
        <Info className='text-xl text-teal-700' weight='regular' />
      </span>
    </Tooltip>
  );
}
