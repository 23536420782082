'use client';
import React from 'react';
import { PortableText } from '@portabletext/react';
import { paragraphs } from '@/components/sections/PortableTextComponents';
import { PortableTextBlock } from 'sanity';

export default function BackButtonText({
  subText,
  className,
}: {
  subText: PortableTextBlock;
  className?: string;
}) {
  return (
    <>
      {subText && (
        <div
          className={`${className} text-center font-normal text-charcoal [&_a]:underline`}
        >
          <PortableText value={subText} components={paragraphs} />
        </div>
      )}
    </>
  );
}
