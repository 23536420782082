'use client';
import { FieldHookConfig, useField } from 'formik';
import { Checkbox } from '@/components/shared/material';
import { CheckboxProps } from '@material-tailwind/react';

export const IKCheckbox = ({
  ...props
}: CheckboxProps & FieldHookConfig<any>) => {
  const [field, meta] = useField(props);
  return (
    <>
      <Checkbox
        crossOrigin={true}
        {...field}
        disabled={props.disabled}
        label={props.label}
        labelProps={props.labelProps}
        required={props.required}
        containerProps={props.containerProps}
        name={field.name}
        type='checkbox'
        className={props.className}
      />
      {meta.touched && meta.error ? (
        <div className='text-xs text-red-500'>{meta.error}</div>
      ) : null}
    </>
  );
};

export default IKCheckbox;
