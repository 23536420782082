'use client';
import React, { useCallback } from 'react';
import { IKButton } from '@/components/shared/material';
import { useRouter } from 'next/navigation';
import { useAtom } from 'jotai';
import { confirmationRedirectURLAtom } from '@/store/formStore';
import { getHomePageUrl } from '@/lib/pathUtils';

export default function BackButtonText() {
  const [confimrationRedirectURL, setConfirmationRedirectURL] = useAtom(
    confirmationRedirectURLAtom
  );
  const router = useRouter();

  const handleClick = useCallback(() => {
    if (confimrationRedirectURL) {
      router.push(confimrationRedirectURL);
      // Set to null to prevent redirecting to the same page
      setConfirmationRedirectURL(null);
    } else {
      const homeURL = getHomePageUrl() !== '' ? getHomePageUrl() : '/';
      router.push(homeURL); // If no redirect URL is set, redirect to the home page
    }
  }, [confimrationRedirectURL, router, setConfirmationRedirectURL]);
  return (
    <>
      <div className='mx-auto mb-2 max-w-xl pl-1.5'>
        <IKButton
          onClick={handleClick}
          variant='text'
          size='sm'
          ripple={false}
          className='group flex items-center gap-2 !overflow-visible text-charcoal'
        >
          <span className='transform duration-150 group-hover:-translate-x-1'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='14'
              height='12'
              viewBox='0 0 14 12'
              fill='none'
            >
              <path
                d='M13.75 5.99995C13.75 6.14913 13.6907 6.29221 13.5852 6.39769C13.4798 6.50318 13.3367 6.56245 13.1875 6.56245H2.17023L6.27297 10.6645C6.32523 10.7167 6.36669 10.7788 6.39497 10.8471C6.42326 10.9154 6.43781 10.9885 6.43781 11.0624C6.43781 11.1364 6.42326 11.2095 6.39497 11.2778C6.36669 11.3461 6.32523 11.4082 6.27297 11.4604C6.22071 11.5127 6.15866 11.5541 6.09038 11.5824C6.0221 11.6107 5.94891 11.6253 5.875 11.6253C5.80109 11.6253 5.7279 11.6107 5.65962 11.5824C5.59134 11.5541 5.52929 11.5127 5.47703 11.4604L0.414531 6.39792C0.362232 6.34567 0.320743 6.28364 0.292435 6.21535C0.264128 6.14706 0.249557 6.07387 0.249557 5.99995C0.249557 5.92603 0.264128 5.85283 0.292435 5.78454C0.320743 5.71626 0.362232 5.65422 0.414531 5.60198L5.47703 0.539478C5.58258 0.43393 5.72573 0.374634 5.875 0.374634C6.02427 0.374634 6.16742 0.43393 6.27297 0.539478C6.37852 0.645026 6.43781 0.788179 6.43781 0.937447C6.43781 1.08671 6.37852 1.22987 6.27297 1.33542L2.17023 5.43745H13.1875C13.3367 5.43745 13.4798 5.49671 13.5852 5.6022C13.6907 5.70769 13.75 5.85076 13.75 5.99995Z'
                fill='#1D1D1B'
              />
            </svg>
          </span>
          <span>Back</span>
        </IKButton>
      </div>
    </>
  );
}
